@import '../../../colors';

.products-view {
  width: 100%;
  height: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  position: relative;
  padding: 32px 0 32px 50px;

  &__list {
    @extend .products-view;
    padding: 32px 50px;
  }

  @media (max-width: 600px) {
    gap: 24px;
    padding: 32px 24px !important;
  }

  .product-cards .product-card {
    :has(&:nth-child(4)) {
      .products-view {
        padding: 32px 50px;
        @media (max-width: 600px) {
          gap: 24px;
          padding: 24px;
        }
      }
    }
  }
  .header {
    display: flex;
    // padding-right: 50px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    @media (max-width: 600px) {
      gap: 8px;
      // padding-right: 0;
    }
  }
  .factory-icon {
    width: 300px;
    height: auto;
    @media (max-width: 600px) {
      width: 185px;
    }
  }

  .constraints {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 1fr 1fr;
    margin-top: 8px;

    .constraint {
      display: flex;
      flex-direction: column;
    }
  }

  .product-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 32px;
    overflow-x: auto;
    gap: 24px;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      border: none;
      background: transparent;
      height: 8px;
    }

    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-track-piece,
    &::-webkit-scrollbar-corner,
    &::-webkit-resizer {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #96d9c9;
    }

    &::-webkit-scrollbar-track {
      background-image: url('~/public/images/icons/line.png');
      background-repeat: repeat-x;
      background-position: center;
    }
    &__list {
      @extend .product-cards;
      padding-bottom: 0;
      flex-direction: column;
    }

    &:has(&:nth-child(5)) {
      .product-card {
        width: 360px !important;
        &:last-child {
          margin-right: 50px;
          width: 100% !important;
        }
      }
    }

    .product-card {
      width: calc(25% - 24px);
      min-width: 360px;
      @media (min-width: 601px) and (max-width: 1200px) {
        width: 360px !important;
        margin-right: 24px;
      }
      @media (max-width: 600px) {
        width: 100% !important;
        min-width: auto;
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;
      overflow: hidden;
      gap: 16px;
      padding-bottom: 8px;
      .product-card {
        width: 100%;
        margin: 0;
      }
    }
  }

  .custom-portfolio-btn {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 32px;
    border: 1px dashed $X_DARK_BLUE;
    border-radius: 24px;
    margin: 0 50px;
    text-align: center;

    &__list {
      @extend .custom-portfolio-btn;
      margin: 0 50px;
    }
    &:has(&:nth-child(5)) {
      width: calc(100% - 50px);
      margin: 24px 50px 24px 0;
    }
    &:hover {
      cursor: pointer;
    }

    img {
      margin-bottom: 12px;
    }
    @media (max-width: 600px) {
      margin: 0;
    }
  }
}

.fact-doc {
  &__list {
    p {
      font-size: 16px !important;
      font-weight: 600 !important;
    }
  }
}
