@import '../../../colors.scss';

.mobile {
  padding-bottom: 80px;
  .add-additional-products-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $UI_COLOR_GRAY_2;
    z-index: 999;
    .btn-add-additional-products {
      width: auto;
    }
  }
}
