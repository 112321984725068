@import '../../../../colors';

.risk-level {
  .title {
    font-size: 0.8rem;
  }
}

.risk-level-empty {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background: $X_LIGHT_GRAY;
  margin: 0 2px;
  @media (max-width: 600px) {
    width: 8px;
    height: 8px;
  }
}

.risk-level-filled-moderate {
  background: $ORANGE !important;
}

.risk-level-filled-high {
  background: $RED !important;
}

.risk-level-filled {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background: $SECONDARY_GREEN;
  margin: 0 1px;
  @media (max-width: 600px) {
    width: 8px;
    height: 8px;
  }
}
