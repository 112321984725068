/* Remove spinner of number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='tel'] {
  direction: ltr !important;
}

body[dir='ltr'] input[type='tel'] {
  text-align: left !important;
}

body[dir='rtl'] input[type='tel'] {
  text-align: right !important;
}

/* Change the pale blue color of chrome auto-complete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.PhoneInputCountry {
  display: none;
}
