$PRIMARY_BLUE: #2482be;
$SECONDARY_GREEN: #7ea356;
$MAIN_DARK: #111827;
$DARK_GREY: #374151;
$CYAN: #66c2fe;
$ACCENT_CYAN: #69c9dd;
$X_LIGHT_CYAN: #f4fbfc;
$DARK_BLUE: #003e78;
$X_DARK_BLUE: #0061a7;
$LIGHT_GREEN: #a9cb85;
$LIGHT_GRAY: #9ca3af;
$X_LIGHT_GRAY: #d1d5db;
$XX_LIGHT_GRAY: #e4e7ec;
$X_LIGHT_GREEN: #dbe9cc;
$XX_LIGHT_GREEN: #e5e7eb;
$LINEAR_GRADIENT: linear-gradient(270deg, #2482be 0, #69c9dd 108.75%);
$LINEAR_GRADIENT_DARK: linear-gradient(270deg, #0061a7 0%, #22a2ff 108.75%);
$WHITE: #ffffff;
$BLACK: #000000;
$TRANSPARENT: transparent;
$XLIGHT_GRAY: #d1d5db;
$ORANGE: #ea9515;
$RED: #ef4444;
$PLACEHOLDER: #000000de;
$GOLD: #ffd700;
$LIGHT_BACKGROUND: #f3f4f6;
$UI_COLOR_GRAY_1: #98A2B3;
$UI_COLOR_GRAY_2: #eeeff2;
