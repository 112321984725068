@import '../../../../colors';

.product-weight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // margin: 12px 0;

  .position {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}
