.account-opening-agreement-view {
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 16px;
}

.account-opening-agreement-view::-webkit-scrollbar {
  width: 5px !important;
  background-color: transparent !important;
  border-radius: 4px !important;
}

.account-opening-agreement-view::-webkit-scrollbar-thumb {
  background-color: #111827 !important;
  border-radius: 4px !important;
}
