/* ts-ignore */
/* eslint-disable */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background-color: #fcfcfb !important;
  touch-action: pan-x pan-y;
  -webkit-overflow-scrolling: touch;
}

body::-webkit-scrollbar {
  width: 5px !important;
  background-color: transparent !important;
  border-radius: 4px !important;
}

body::-webkit-scrollbar-thumb {
  background-color: #111827 !important;
  border-radius: 4px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.circular-progress-background {
  position: absolute;
  z-index: 1;
  right: 0;
  width: 250px !important;
  height: 250px !important;
}

.circular-progress-background svg {
  color: #d1d5db;
}

.circular-progress-background svg circle {
  stroke-dashoffset: 0px !important;
}

.circular-progress-foreground {
  position: relative;
  z-index: 2;
  width: 250px !important;
  height: 250px !important;
}

.circular-progress-foreground svg {
  color: #7ea356;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@media (max-width: 900px) and (orientation: landscape) {
  canvas {
    margin: 0 auto;
  }
}

form#checkout-page {
  background: white !important;
  background-color: white !important;
}

/**
 * Set input::placeholder style
 * Because of it is breaking EmotionCache prefixer plugin
 */
.MuiInputBase-root input::placeholder {
  color: #000000de;
  opacity: 1;
  font-size: clamp(0.5rem, 1vw + 1rem, 0.875rem);
}

.MuiInputBase-root[class*='muiltr-'] input::placeholder {
  font-family: 'Montserrat', sans-serif;
}

.MuiInputBase-root[class*='muirtl-'] input::placeholder {
  font-family: 'Noto Kufi Arabic', sans-serif;
}

.doc-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .user-doc {
    width: 160px;
  }

  .fact-sheet {
    width: 140px;
  }
}
