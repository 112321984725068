.widgets-list {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  flex-wrap: wrap !important;
}

.desktop{
  .widgets-list {
    justify-content: space-between !important;
    flex-wrap: nowrap !important;
    > .MuiGrid-item{
      max-width: unset !important;
      flex-basis: unset !important;
      flex-grow: 1;
    }
  }
}