@import '../../../../colors';

.fund-selector {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  width: 100%;

  .fund-item {
    display: flex;
    flex-direction: row;
    width: 315px;
    padding: 24px;
    border: 1px solid $XX_LIGHT_GRAY;
    border-radius: 24px;

    @media (max-width: 600px) {
      width: 100%;
    }

    &:hover {
      cursor: pointer;
    }

    .MuiCheckbox-root:hover {
      background-color: unset;
    }
    .MuiCheckbox-root {
      padding: 0 !important;
      border-radius: 24px !important;
    }

    .fund-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 40px);

      .asset {
        font-size: 10px;
      }

      .progress-bar {
        .MuiLinearProgress-bar1Buffer {
          background: $DARK_BLUE;
        }

        .MuiLinearProgress-bar2Buffer {
          background: $CYAN;
        }

        .MuiLinearProgress-dashed {
          -webkit-animation: none;
          -o-animation: none;
          animation: none;
          background: $LIGHT_GREEN;
        }
      }

      // .product-info {
      //   margin-top: 10px;
      // }
    }
  }

  .fund-item-checked {
    border-color: $X_DARK_BLUE;
    background-color: $LIGHT_BACKGROUND;
  }
  .doc-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;

    .user-doc {
      width: 160px;
    }

    .fact-sheet {
      width: 140px;
    }
  }
}
