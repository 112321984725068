@import '../../../../colors';

.product-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .info {
    display: flex;
    flex-direction: column;
    width: 100px;
    text-align: center;

    &__en {
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
    }

    &__ar {
      &:first-child {
        text-align: right;
      }
      &:last-child {
        text-align: left;
      }
    }
  }
}
