.custom-product-view {
  width: 100%;
  height: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  gap: 32px;
  padding: 32px 50px;

  @media (max-width: 600px) {
    gap: 8px;
    padding: 32px 24px;
  }

  .portfolio-icon {
    margin-top: 32px;
  }
}
