@import '../../../../colors';

.product-card {
  padding: 20px;
  border: 1px solid $XX_LIGHT_GRAY;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  height: 700px;
  &__in-weights {
    margin-bottom: 8px;
  }
  &__in-list {
    height: auto;
    border: 1px solid #e4e7ec;
    width: 100% !important;
    padding: 20px 32px;
    gap: 16px;
    background: #f7f8fa;
    @media (max-width: 600px) {
    }
  }

  @media (max-width: 600px) {
    height: auto;
    width: 100%;
    padding: 20px;
    background-color: #f3f4f6;
    border: 1px solid #e4e7ec;
  }

  &:hover {
    cursor: pointer;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media (max-width: 600px) {
      align-items: flex-start;
    }
    @media (min-width: 600px) {
      svg {
        display: none;
      }
    }

    .toggleIcon {
      position: absolute;
      right: 0;
      top: 5px;
    }
  }

  .header-in-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 600px) {
      display: block;
    }
  }

  .title-in-list {
    margin-top: auto !important;
    margin-bottom: auto !important;
    &__text {
      @extend .title-in-list;
      width: 100%;
      @media (max-width: 600px) {
        width: 100%;
        padding-bottom: 16px;
      }
    }
    &__buttons {
      @extend .title-in-list;
      display: flex;
      justify-content: center !important;
      width: 20%;
      @media (max-width: 600px) {
        width: 100%;
        justify-content: flex-start !important;
        padding-bottom: 16px;
      }
    }
    &__no-padding {
      @media (max-width: 600px) {
        padding-bottom: 0 !important;
      }
    }
    &__hint {
      @extend .title-in-list;
      width: 25%;
      margin-right: 2.5%;
      margin-left: 2.5%;
      @media (max-width: 600px) {
        width: 100%;
      }
      &__selected {
        @media (max-width: 600px) {
          padding-bottom: 16px;
        }
      }
      &__ar {
        width: 20%;
        @media (max-width: 600px) {
          width: 100%;
        }
      }
      &__hide {
        visibility: hidden !important;
      }
    }
    &__fee {
      @extend .title-in-list;
      width: 10%;
      background-color: #7ea256;
      border-radius: 8px;
      padding: 8px 16px;
      color: white;
      @media (max-width: 600px) {
        width: 100%;
      }
      &__desktop {
        display: flex !important;
        width: 136px;
        @media (max-width: 600px) {
          display: none !important;
        }
        &__ar {
          width: 20%;
        }
      }
      &__mobile {
        display: none !important;
        @media (max-width: 600px) {
          display: flex !important;
        }
      }
    }
  }

  .description {
    font-size: 14px;
  }

  .separator {
    width: 100%;
    margin: 16px 0;
    border-bottom: 1px solid $UI_COLOR_GRAY_1;
    &__list {
      @extend .separator;
      border-bottom: 1px solid $UI_COLOR_GRAY_1;
      margin: 0 !important;
    }
    &__custom-margin {
      margin: 32px 0 16px;
      @media (max-width: 600px) {
        margin: 16px 0;
      }
    }
  }

  .doc-buttons {
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin-bottom: 16px;
    gap: 8px;
    &__no-margin {
      @extend .doc-buttons;
      margin-bottom: 0;
    }

    .user-doc {
      width: 160px;
    }

    .fact-sheet {
      width: 140px;
    }
  }

  .gics-weight {
    min-width: 100%;
    &__list {
      @extend .gics-weight;
      min-width: 300px;
      @media (max-width: 600px) {
        min-width: 100%;
      }
    }
  }

  .weights {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__list {
      @extend .weights;
      flex-direction: row;
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      margin-top: 16px;
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-track {
        border: none;
        background: transparent;
        height: 4px;
      }

      &::-webkit-scrollbar-button,
      &::-webkit-scrollbar-track-piece,
      &::-webkit-scrollbar-corner,
      &::-webkit-resizer {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #96d9c9;
      }

      &::-webkit-scrollbar-track {
        background-image: url('~/public/images/icons/line.png');
        background-repeat: repeat-x;
        background-position: center;
      }
      @media (max-width: 600px) {
        margin-top: 0;
      }
    }

    .MuiCircularProgress-root {
      align-self: center;
      margin: 24px;
    }
  }

  .survey-block {
    display: flex;
    margin-bottom: 32px;
    align-items: center;
    &__less-margin {
      margin-bottom: 16px;
    }
    .img {
      &-ar {
        margin-left: 8px;
      }
    }
    p {
      margin: 0 0 0 10px;
      font-weight: 700;
      font-size: 14px;
      color: $SECONDARY_GREEN;
    }
  }

  .fee {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0;
    p {
      margin: 0 5px;
    }
  }
}

.arabic {
  .header {
    .toggleIcon {
      position: absolute;
      right: auto;
      left: 0;
    }
  }
}

.product-card-selected {
  border: 2px solid $X_DARK_BLUE;
  background-color: $LIGHT_BACKGROUND;
}

.separator__list.separator__custom-margin {
  display: none;
}

.invest-icon {
  &__en {
    margin-right: 16px;
  }
  &__ar {
    margin-left: 16px;
  }
}

.header-img-text {
  width: 100%;
  align-items: flex-start;
  img {
    margin-top: 4px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  &__list {
    width: 40%;
    @extend .header-img-text;
    display: flex;
  }
  @media (max-width: 600px) {
    display: flex;
    width: 100%;
  }
}

.product-weight-list {
  height: auto;
  overflow-y: auto;

  &__en {
    padding-right: 4px;
  }
  &__ar {
    padding-left: 4px;
  }
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border: none;
    background: transparent;
    width: 4px;
  }

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-track-piece,
  &::-webkit-scrollbar-corner,
  &::-webkit-resizer {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #96d9c9;
  }

  &::-webkit-scrollbar-track {
    background-image: url('~/public/images/icons/line.png');
    background-repeat: repeat-x;
    background-position: center;
  }
  @media (max-width: 600px) {
    overflow-y: hidden;
    padding-right: 0;
  }
}
