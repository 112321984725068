.swiper-pagination {
  position: unset;
  margin-top: 12px;
}

.swiper-pagination-bullet {
  background-color: #e5e7eb;
  opacity: 1;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #9ca3af;
}
