.email-form {
  position: absolute;
  width: 440px;
  height: fit-content;
  min-height: 693px;
  bottom: 50px;
  z-index: 2000;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

@media (max-width: 600px) {
  .email-form {
    position: absolute;
    width: calc(100vw - 30px);
    height: fit-content;
    min-height: 693px;
    bottom: 50px;
    z-index: 2000;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    left: -10px !important;
  }
}
