@import '../../../../colors';

.doc-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-around;
  border: 1px solid $DARK_GREY;
  border-radius: 8px;
  // width: 190px;
  padding: 8px 12px;

  &:hover {
    cursor: pointer;
  }

  .text {
    font-size: 0.8rem;
    font-weight: bold;
    &-en {
      margin-left: 8px;
    }
    &-ar {
      margin-right: 8px;
    }

    @media (max-width: '400px') {
      font-size: 10px;
    }
  }
}
